import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class About extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Thank you for signing up!"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>Thank you for signing up!</h1>

        <p>Good stuff will be delivered straight to your inbox.</p>
      </Layout>
    )
  }
}

export default About
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
